import { API_HOST } from "src/constants/env";
import { makeApi } from "types-to-fetchers";

export const makeAPIUrl = (protocol: "https") => {
  return `${protocol}://${API_HOST}`;
};

export const api = makeApi<any, any>(
  {
    "affiliate/visitor": ["POST"],
    "affiliate/visitor/:id": ["GET"],
    "application": ["POST"],
    "delivery/check-address": ["POST"],
    "order/getPrice": ["POST"],
    "complaint": ["POST"],
    "check-promo":['POST'],
    "v2/polygon":["GET"],
    "current-meal-prices":['GET'],
    "v2/tag-replacements":['GET'],
    "order/quick":['POST'],
    "pickup-points":['GET']
  } as any,
  {
    baseURL: makeAPIUrl("https"),
  }
);
